import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Grid from '@mui/material/Grid'
import * as S from './VideoPreview.style'
import { HomepageMobileVideoBG } from '@components/icons'
import Spacer from '@components/ui/Spacer'

export const VideoPreview = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (ref && inView === true) {
      const videoElement =
        (document.getElementById('homepage-video') as HTMLVideoElement) || null
      if (videoElement) {
        videoElement.currentTime = 0
        videoElement.playbackRate = 0.8
      }
    }
  }, [ref, inView])

  return (
    <S.Container>
      <Grid container direction={'column'}>
        <Spacer size={32} />
        <Grid item xs={12}>
          <S.HeaderText>{'Hand-Picked'}</S.HeaderText>
          <S.HeaderText>{'Premium Plants'}</S.HeaderText>
        </Grid>
        <Spacer size={16} />
        <Grid item xs={12}>
          <S.SubHeaderText>
            {'We carefully select only the healthiest,'}
          </S.SubHeaderText>
          <S.SubHeaderText>
            {'most vibrant plants to ship from our'}
          </S.SubHeaderText>
          <S.SubHeaderText>{'greenhouse to your doorstep'}</S.SubHeaderText>
        </Grid>
        <Spacer size={16} />
        <S.VideoPreviewContainer>
          <S.VideoBGAbsoluteContainer>
            <S.VideoBGContainer>
              <HomepageMobileVideoBG />
            </S.VideoBGContainer>
          </S.VideoBGAbsoluteContainer>
          <S.VideoBGAbsoluteContainer style={{ zIndex: 1 }}>
            <S.VideoPlayerContainer>
              <S.VideoPlayer
                ref={ref}
                id='homepage-video'
                width='305'
                height='305'
                poster='https://res.cloudinary.com/easyplant/image/upload/f_auto/v1689159139/homepage2/mobile/homepage-video-poster.png'
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src='https://res.cloudinary.com/easyplant/video/upload/v1687423027/homepage2/mobile/homepage-video.mp4'
                  type='video/mp4'
                />
              </S.VideoPlayer>
            </S.VideoPlayerContainer>
          </S.VideoBGAbsoluteContainer>
        </S.VideoPreviewContainer>
        <Spacer size={16} />
      </Grid>
    </S.Container>
  )
}
