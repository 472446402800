import { styled } from '@mui/material'
import React, { FC } from 'react'
import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'
import { useDefaultCollectionsURL } from '../../../hooks/useDefaultCollectionsURL'
import useSafeRouterPush from 'hooks/useSafeRouterPush'

type TransparentButtonProps = {
  text: string
  link?: string
  handleOnClick?: () => void
}

const StyledTransparentButton = styled('button')`
  width: 100%;
  height: 100%;
  background: rgba(223, 233, 216, 0.24);
  color: white;
  font-family: 'Fraunces', sans-serif;
  text-align: center;
  outline: 0;
  border-radius: 4px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02em;
  cursor: pointer;

  border: 1px solid white;
  box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px;

  &:hover {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(hsla(0, 0%, 100%, 0.1)),
      to(hsla(0, 0%, 100%, 0.1))
    );
    background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.1),
      hsla(0, 0%, 100%, 0.1)
    );
    box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.09);
  }
`

const TransparentButton: FC<TransparentButtonProps> = ({
  text,
  handleOnClick,
}) => {
  const safeRouterPush = useSafeRouterPush()
  const defaultCollectionsURL = useDefaultCollectionsURL()
  const onClickButton = () => {
    handleOnClick?.()
    const href = LocalStorage.getIsOnboardingDone()
      ? defaultCollectionsURL
      : 'onboarding'
    safeRouterPush(href)
  }
  return (
    <StyledTransparentButton onClick={onClickButton}>
      {text}
    </StyledTransparentButton>
  )
}

export default TransparentButton
