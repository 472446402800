import React from 'react'

import Grid from '@mui/material/Grid'
import Image from 'next/image'
import * as S from './GiftThatLast.style'
import Spacer from '@components/ui/Spacer'

export const GiftThatLast = () => {
  return (
    <S.GiftThatLastContainer>
      <Grid container direction={'column'}>
        <Spacer size={44} />
        <Grid item xs={12}>
          <S.HeaderText>{'A Gift That Lasts'}</S.HeaderText>
        </Grid>
        <Spacer size={16} />
        <Grid item xs={12}>
          <S.SubHeaderText>
            {'easyplant is the perfect gift that'}
          </S.SubHeaderText>
          <S.SubHeaderText>
            {'brightens every home with the beauty'}
          </S.SubHeaderText>
          <S.SubHeaderText>{'of nature'}</S.SubHeaderText>
        </Grid>
        <Spacer size={24} />

        <S.ImageContainer>
          <Image
            fill
            style={{ objectFit: 'cover' }}
            src={
              'https://res.cloudinary.com/easyplant/image/upload/f_auto/v1687435277/homepage2/mobile/GiftThatLast.png'
            }
            alt={'A gift that lasts'}
            sizes='100vw'
          />
        </S.ImageContainer>
      </Grid>
    </S.GiftThatLastContainer>
  )
}
