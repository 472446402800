import styled from 'styled-components'

export const CollectionsListMobileContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;

  a:nth-of-type(5),
  a:nth-of-type(6) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`
