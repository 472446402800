import React, { ReactNode, useEffect, useState } from 'react'

const NoSSR: React.FC<NoSSRProps> = ({ children, skeleton }) => {
  const [render, setRender] = useState(false)
  useEffect(() => setRender(true), [])
  if (render) {
    return <>{children}</>
  }
  if (skeleton) {
    return <>{skeleton}</>
  }
  return null
}
export default NoSSR

type NoSSRProps = { skeleton?: ReactNode; children: ReactNode }
