import { RenderOnDesktop, RenderOnMobile } from '@config/Theme/sizeDisplays'
import { FAQ as FAQMobile } from './FAQMobile/FAQ'
import { FAQ as FAQDesktop } from './FAQDesktop/FAQ'

type FAQProps = {
  faqData: { title: string; text: string }[]
  origin: string
  showFooterSeperator?: boolean
}

export const FAQ = ({
  faqData,
  origin,
  showFooterSeperator = true,
}: FAQProps) => {
  return (
    <>
      <RenderOnDesktop>
        <FAQDesktop
          faqData={faqData}
          origin={origin}
          showFooterSeperator={showFooterSeperator}
        />
      </RenderOnDesktop>
      <RenderOnMobile>
        <FAQMobile
          faqData={faqData}
          origin={origin}
          showFooterSeperator={showFooterSeperator}
        />
      </RenderOnMobile>
    </>
  )
}
