import React from 'react'
import * as S from './CollectionsListMobile.styles'

export default function CollectionsListMobile({ children }) {
  return (
    <S.CollectionsListMobileContainer>
      {children}
    </S.CollectionsListMobileContainer>
  )
}
