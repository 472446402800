import styled from 'styled-components'
import Image from 'next/legacy/image'

interface CollectionItemProps {
  $alignItems: string
  $imgSize?: number
}

export const CollectionItem = styled.div<CollectionItemProps>`
  display: inline-flex;
  flex-direction: column;
  align-items: ${({ $alignItems }) => $alignItems};
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  margin: 10px 5px;
  cursor: pointer;

  & > img {
    width: ${({ $imgSize }) => ($imgSize ? $imgSize + 'px' : '100%')};
    border-radius: 4px;
    margin-bottom: 10px;
    display: block;
  }

  & > p {
    margin: 8px 0 0 0;
    color: ${({ theme }) => theme.colors.black};
  }

  & > span:nth-child(2) {
    margin-top: 8px;
  }
};
`

export const StyledImage = styled(Image)`
  border-radius: 3px;
`
