import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Image from 'next/image'
import * as S from './MarketingSections.style'
import Helpers from 'lib/utils/helpers'
import analyticsManager from 'services/analytics/analyticsManager'
import EVENT_NAMES from 'consts/eventNames'
import Spacer from '@components/ui/Spacer'
import { HomepageDesktopVideoBG } from '@components/icons'
import { ChatWithUsButton } from '@components/Pages/NewHomepage/Mobile/ChatWithUs/ChatWithUs'
import { useTheme } from '@config/Theme/theme'
import { ANALYTICS_SERVICES_NAMES } from 'services/analytics/analyticsConfig'
import { TIKTOK_EVENT_NAMES } from 'services/analytics/TiktokService'
import { useHomepageCTAButton } from '../../../../../hooks/useHomepageCTAButton'
import useSafeRouterPush from 'hooks/useSafeRouterPush'

export const MarketingSections = () => {
  const theme = useTheme()
  const safeRouterPush = useSafeRouterPush()
  const { ctaText, ctaLink } = useHomepageCTAButton()
  const tiktokService = analyticsManager.getService(
    ANALYTICS_SERVICES_NAMES.TIKTOK
  )

  const shopPlantsClicked = () => {
    tiktokService?.trackTiktokEvent(TIKTOK_EVENT_NAMES.CLICK_BUTTON)
    analyticsManager.track(EVENT_NAMES.HOMEPAGE_SHOP_PLANTS_CLICKED, {
      url: ctaLink,
      device: 'desktop',
    })
    safeRouterPush(ctaLink)
  }

  const chatWithUsClicked = () => {
    try {
      Helpers.openIntercomChat(`Homepage Desktop`)
    } catch (e) {
      console.error(e)
    }
  }

  const ctaContainerRow1 = (
    <CallToActionContainer
      titleLines={['Hand-Picked', 'Premium Plants']}
      subtitleLines={[
        'We carefully select only the healthiest, most',
        'vibrant plants to ship from our greenhouse',
        'to your doorstep',
      ]}
      ctaButton={
        <ShopNowButton ctaText={ctaText} onActionClick={shopPlantsClicked} />
      }
    />
  )
  const videoRow1 = <MarketingVideo />
  const ctaContainerRow2 = (
    <CallToActionContainer
      titleLines={["We're Here", 'To Make It Easy']}
      subtitleLines={[
        'Get in touch with our plant experts for',
        'personal plant care guidance',
      ]}
      ctaButton={<ChatWithUsButton onClick={chatWithUsClicked} />}
    />
  )

  const imageRow2 = (
    <MarketingImage imageName={'marketing-msg1.png'} alt='marketing image 1' />
  )

  const ctaContainerRow3 = (
    <CallToActionContainer
      titleLines={['A Gift That Lasts']}
      subtitleLines={[
        'easyplant is the perfect gift that brightens',
        'every home with the beauty of nature',
      ]}
      ctaButton={
        <ShopNowButton ctaText={ctaText} onActionClick={shopPlantsClicked} />
      }
    />
  )
  const imageRow3 = (
    <MarketingImage imageName={'marketing-msg2.png'} alt='marketing image 2' />
  )

  return (
    <S.MainContainer>
      <MarketingRow
        leftChild={videoRow1}
        rightChild={ctaContainerRow1}
        bgcolor={theme.colors.cream5}
      />
      <MarketingRow
        leftChild={ctaContainerRow2}
        rightChild={imageRow2}
        bgcolor={theme.colors.cream3}
      />
      <MarketingRow
        leftChild={imageRow3}
        rightChild={ctaContainerRow3}
        bgcolor={theme.colors.cream5}
      />
    </S.MainContainer>
  )
}

const MarketingVideo = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (ref && inView === true) {
      const videoElement =
        (document.getElementById('homepage-video') as HTMLVideoElement) || null
      if (videoElement) {
        videoElement.currentTime = 0
      }
    }
  }, [ref, inView])

  return (
    <S.VideoPreviewContainer>
      <S.VideoBGAbsoluteContainer>
        <S.VideoBGImageDiv>
          <Image
            width={0}
            height={0}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            src={HomepageDesktopVideoBG}
            alt={'video background'}
            sizes='100vw'
          />
        </S.VideoBGImageDiv>
      </S.VideoBGAbsoluteContainer>
      <S.VideoPlayerContainer style={{ zIndex: 1 }}>
        <S.VideoPlayer
          ref={ref}
          id='homepage-video'
          poster='https://res.cloudinary.com/easyplant/image/upload/f_auto/v1687353725/homepage2/desktop-homepage-video-poster.png'
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            src='https://res.cloudinary.com/easyplant/video/upload/v1687423027/homepage2/desktop-homepage-video.mp4'
            type='video/mp4'
          />
        </S.VideoPlayer>
      </S.VideoPlayerContainer>
    </S.VideoPreviewContainer>
  )
}

const MarketingRow = ({ leftChild, rightChild, bgcolor }) => {
  return (
    <S.MarketingRowVerticalContainer bgcolor={bgcolor}>
      <Spacer size={40} />
      <S.MarketingRowHorizontalContainer>
        {leftChild}
        <Spacer size={24} />
        {rightChild}
      </S.MarketingRowHorizontalContainer>
      <Spacer size={40} />
    </S.MarketingRowVerticalContainer>
  )
}

const MarketingImage = ({ imageName, alt }) => {
  return (
    <S.ImageContainer>
      <Image
        width={0}
        height={0}
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        src={
          'https://res.cloudinary.com/easyplant/image/upload/f_auto/v1689146168/homepage2/' +
          imageName
        }
        alt={'marketing image 1'}
        sizes='100vw'
      />
    </S.ImageContainer>
  )
}

const CallToActionContainer = ({ titleLines, subtitleLines, ctaButton }) => {
  return (
    <S.CallToActionContainer>
      {titleLines.map((line) => (
        <S.CallToActionTitle key={line}>{line}</S.CallToActionTitle>
      ))}
      <Spacer size={16} />
      {subtitleLines.map((line) => (
        <S.CallToActionSubtitle key={line}>{line}</S.CallToActionSubtitle>
      ))}
      <Spacer size={24} />
      {ctaButton}
    </S.CallToActionContainer>
  )
}

interface ShopNowButtonProps {
  ctaText: string
  onActionClick: () => void
}
const ShopNowButton: React.FC<ShopNowButtonProps> = ({
  ctaText,
  onActionClick,
}) => (
  <S.CallToActionButton onClick={() => onActionClick()}>
    {ctaText}
  </S.CallToActionButton>
)
