import { styled } from '@mui/material'

export const PageLoader = styled('div')`
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: rgba(${({ theme }) => theme.colors.cream}, 0.96);
  transition: all 1s;
  transition-delay: 0.4s;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
`

export const ContentLoader = styled('div')`
  height: 260px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: rgba(${({ theme }) => theme.colors.cream}, 0.96);
  transition: all 1s;
  transition-delay: 0.4s;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
`

export const PageLoaderTextContainer = styled('div')`
  margin-top: 15px;
`

export const CartLoader = styled('div')`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 252, 249, 0.96);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 1s;
  transition-delay: 0.4s;
`
