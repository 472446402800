import { styled } from '@mui/material'
import {
  LAYOUT_DESKTOP_THRESHOLD,
  LAYOUT_MOBILE_MAX_WIDTH,
} from '@utils/styleUtils'

export const HowItWorksContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream5};
`

export const HeaderText = styled('div')`
  font-family: 'Fraunces';
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`

export const HowItWorksText = styled('div')`
  font-family: 'Mixtiles Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  font-size: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black3};
`

export const HowItWorksImageContainer = styled('div')`
  position: relative;
  width: vw;
  height: calc(100vw * 0.85);
  @media only screen and (min-width: ${LAYOUT_MOBILE_MAX_WIDTH}px) and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) {
    width: ${LAYOUT_MOBILE_MAX_WIDTH}px;
    height: calc(${LAYOUT_MOBILE_MAX_WIDTH}px * 0.85);
  }
`
