import { styled } from '@mui/material'

export const MainContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const VideoRowContainer = styled('div')`
  padding: 0 calc((100% - 1220px) / 2); // screen width should be 1220px and we add equal padding on the sides
  display: flex;
  height: 450px;
  flex-direction: row;
  align-items: space-between;
  background-color: ${({ theme }) => theme.colors.cream5};
`

type MarketingRowVerticalContainerProps = {
  bgcolor: string
}

export const MarketingRowVerticalContainer = styled(
  'div'
)<MarketingRowVerticalContainerProps>`
  padding: 0 calc((100% - 1220px) / 2); // screen width should be 1220px and we add equal padding on the sides
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.bgcolor};
`

export const MarketingRowHorizontalContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: space-between;
`

// Video Preview start
export const VideoPreviewContainer = styled('div')`
  display: flex;
  flex: 1;
  position: relative;
`

export const VideoBGAbsoluteContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

export const VideoBGImageDiv = styled('div')`
  position: relative;
  margin-top: -5%;
  width: 80%;
  height: 115%;
`

export const VideoPlayerContainer = styled('div')`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const VideoPlayer = styled('video')`
  border-radius: 8px;
  width: 100%;
  height: auto;
`
// Video Preview end

// Call to action start
export const CallToActionContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CallToActionTitle = styled('div')`
  max-width: 400px;
  font-family: 'Fraunces';
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  letter-spacing: -0.8px;
  color: ${({ theme }) => theme.colors.black3};
`

export const CallToActionSubtitle = styled('div')`
  max-width: 425px;
  font-family: Mixtiles Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-size: 20px;
  text-align: center;
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.black3};
`

export const CallToActionButton = styled('div')`
  cursor: pointer;
  display: flex;
  margin: 0 auto;
  height: 52px;
  padding: 14px 32px 15px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.green};

  color: ${({ theme }) => theme.colors.white};
  font-family: Fraunces;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: -0.36px;

  &:hover {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(hsla(0, 0%, 100%, 0.1)),
      to(hsla(0, 0%, 100%, 0.1))
    );
    background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.1),
      hsla(0, 0%, 100%, 0.1)
    );
    box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.09);
  }
`

export const ImageContainer = styled('div')`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
`
