import { styled } from '@mui/material'

export const FAQContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream5};
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: -16px;
`

export const FooterSeparator = styled('div')`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`

export const HeaderText = styled('div')`
  padding: 0 35px;
  font-family: 'Fraunces';
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`
