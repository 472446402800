import React from 'react'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { FONTS } from '@config/Theme/theme'
import { FaqArrowCollapse } from '../icons'
import analyticsManager from '../../services/analytics/analyticsManager'
import EVENT_NAMES from '../../consts/eventNames'
import { FAQPageJsonLd } from 'next-seo'

interface FAQData {
  title: string
  text: string
}

type BorderDirection = 'all' | 'bottom' | 'none'

interface FAQListProps {
  faqData: FAQData[]
  origin: string
  maxItems?: number
  containerStyle?: React.CSSProperties
  useInnerHtml?: boolean
  titleTextColor?: string
  textTextColor?: string
  titleFontSize?: number
  textFontSize?: number
  borderColor?: string
  borderDirection?: BorderDirection
  backgroundColor?: string
  boldTitleOnOpen?: boolean
  itemPadding?: string
  itemMarginTop?: string
  itemTextMarginTop?: string
  initiallyOpenedItemIndex?: number
}

export const FAQList: React.FC<FAQListProps> = ({
  faqData = [],
  origin,
  maxItems = 100,
  containerStyle = {},
  useInnerHtml = true,
  titleTextColor = '#171615',
  textTextColor = 'black',
  titleFontSize = 16,
  textFontSize = 16,
  borderColor = '#E8E3DA',
  borderDirection = 'all',
  backgroundColor = 'white',
  boldTitleOnOpen = true,
  itemPadding = '16px',
  itemMarginTop = '8px',
  itemTextMarginTop = '16px',
  initiallyOpenedItemIndex,
}) => {
  const [openedItem, setOpenedItem] = React.useState(initiallyOpenedItemIndex)

  const onItemClick = (index) => {
    setOpenedItem(openedItem === index ? null : index)
  }
  function stripHTML(html) {
    return html.replace(/<[^>]+>/g, '')
  }

  return (
    <Box sx={containerStyle}>
      <FAQPageJsonLd
        mainEntity={faqData.map(({ title, text }) => ({
          questionName: title,
          acceptedAnswerText: stripHTML(text),
        }))}
      />
      {faqData.slice(0, maxItems).map((faqDataItem, i) => (
        <FaqItem
          key={`${faqDataItem.title}`}
          title={faqDataItem.title}
          index={i}
          open={openedItem === i}
          onItemClick={onItemClick}
          text={faqDataItem.text}
          useInnerHtml={useInnerHtml}
          origin={origin}
          titleTextColor={titleTextColor}
          textTextColor={textTextColor}
          titleFontSize={titleFontSize}
          textFontSize={textFontSize}
          borderColor={borderColor}
          borderDirection={borderDirection}
          backgroundColor={backgroundColor}
          boldTitleOnOpen={boldTitleOnOpen}
          itemPadding={itemPadding}
          itemMarginTop={itemMarginTop}
          itemTextMarginTop={itemTextMarginTop}
        />
      ))}
    </Box>
  )
}

interface FaqItemProps {
  title: string
  text: string
  index: number
  open: boolean
  onItemClick: (index: number) => void
  useInnerHtml: boolean
  origin: string
  titleTextColor: string
  textTextColor: string
  titleFontSize: number
  textFontSize: number
  borderColor: string
  borderDirection: BorderDirection
  backgroundColor: string
  boldTitleOnOpen: boolean
  itemPadding: string
  itemMarginTop: string
  itemTextMarginTop: string
}

const FaqItem: React.FC<FaqItemProps> = ({
  title,
  text,
  index,
  open,
  onItemClick,
  useInnerHtml,
  origin,
  titleTextColor,
  textTextColor,
  titleFontSize,
  textFontSize,
  borderColor,
  borderDirection,
  backgroundColor,
  boldTitleOnOpen,
  itemPadding,
  itemMarginTop,
  itemTextMarginTop,
}) => {
  const classes = useStyles()

  const onExpandCollapse = () => {
    if (!open) {
      analyticsManager.track(EVENT_NAMES.FAQ_QUESTION_EXPAND, { title, origin })
    }
    onItemClick(index)
  }

  const border =
    borderDirection === 'all'
      ? { border: '1px solid', borderRadius: '8px' }
      : borderDirection === 'bottom'
      ? { borderBottom: '1px solid' }
      : { border: 'none' }

  return (
    <Box
      onClick={onExpandCollapse}
      sx={{
        backgroundColor: backgroundColor,
        padding: itemPadding,
        marginTop: index == 0 ? '0px' : itemMarginTop,
        cursor: 'pointer',
        ...border,
        borderColor: borderColor,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            paddingRight: '12px',
            fontSize: `${titleFontSize}px`,
            fontWeight: open && boldTitleOnOpen ? 600 : 400,
            color: titleTextColor,
            fontFamily: FONTS.FRAUNCS,
            lineHeight: '150%',
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            transform: `${open ? 'rotate(180deg)' : 'rotate(0deg)'}`,
            transition: 'transform 0.3s ease-in-out',
          }}
        >
          <FaqArrowCollapse />
        </Box>
      </Box>
      {/* Text show only if open */}
      <Collapse in={open} timeout='auto'>
        <Box
          className={classes.innerText}
          sx={{
            marginTop: itemTextMarginTop,
            fontSize: `${textFontSize}px`,
            fontWeight: 400,
            color: textTextColor,
            fontFamily: `'Mixtiles Sans', sans-serif;`,
            lineHeight: '150%',
          }}
        >
          {useInnerHtml ? (
            <div dangerouslySetInnerHTML={{ __html: text }} />
          ) : (
            text
          )}
        </Box>
      </Collapse>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  innerText: {
    '& p': {
      margin: 0,
    },
  },
}))
