import React, { useRef } from 'react'
import * as S from './CommunityCarousel.style'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import Image from 'next/image'

const CLOUDINARY_URL_BASE =
  'https://res.cloudinary.com/easyplant/image/upload/f_auto/v1691397662/homepage2/carousel/'

export const COMMUNITY_IMAGES = [
  CLOUDINARY_URL_BASE + 'carousel_1.png',
  CLOUDINARY_URL_BASE + 'carousel_2.png',
  CLOUDINARY_URL_BASE + 'carousel_3.jpg',
  CLOUDINARY_URL_BASE + 'carousel_4.png',
  CLOUDINARY_URL_BASE + 'carousel_5.jpg',
  CLOUDINARY_URL_BASE + 'carousel_6.png',
  CLOUDINARY_URL_BASE + 'carousel_7.png',
]

export const imageTags = [
  '@juwaun2013',
  '@its.rachelanne',
  '@blackgirlsgardening',
  '@nikkispender',
  '@robinblickman',
  '@hudsoninthehood',
  '@audreycrispinterior',
  '@dreamgreendiy',
]

export const CommunityCarousel = () => {
  const [currentSlide, setCurrentSlide] = React.useState(2)

  const settings = {
    dots: false,
    arrows: false,
    swipeToSlide: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    afterChange: (current) => setCurrentSlide(current),
  }
  const sliderRef = useRef(null)

  const onCardClick = (idx) => {
    setCurrentSlide(idx)
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(idx)
    }
  }

  return (
    <S.ImageGallaryContainer>
      <Slider {...settings} ref={sliderRef}>
        {COMMUNITY_IMAGES.map((image, index) => (
          <SingleCard
            key={image}
            image={image}
            imageTag={imageTags[index]}
            onClick={() => onCardClick(index)}
          />
        ))}
      </Slider>
      <S.DotsContainer>
        {COMMUNITY_IMAGES.map((image, index) => (
          <S.Dot
            onClick={() => onCardClick(index)}
            key={image}
            style={index == currentSlide ? { opacity: 1 } : {}}
          />
        ))}
      </S.DotsContainer>
    </S.ImageGallaryContainer>
  )
}

const SingleCard = ({ image, imageTag, onClick }) => (
  <S.SingleCardContainer onClick={onClick}>
    <S.ImageContainer>
      <Image
        loading='eager'
        fill
        style={{ objectFit: 'cover' }}
        src={image}
        alt={imageTag}
        sizes='100vw'
      />
    </S.ImageContainer>
    <S.SingleCardTag>{imageTag}</S.SingleCardTag>
  </S.SingleCardContainer>
)
