import React from 'react'
import Grid from '@mui/material/Grid'
import * as S from './PlantsForEverySpace.style'
import Spacer from '@components/ui/Spacer'
import CollectionsGrid from '@components/CollectionsGrid/CollectionsGrid'

export const PlantsForEverySpace = () => {
  return (
    <S.PlantsForEverySpaceContainer>
      <Grid container direction={'column'}>
        <Spacer size={32} />
        <Grid item xs={12}>
          <S.HeaderText>{'Plants For Every'}</S.HeaderText>
          <S.HeaderText>{'Space'}</S.HeaderText>
        </Grid>
        <Spacer size={16} />
        <Grid item xs={12}>
          <S.SubHeaderText>
            {'Discover our wide selection of plants'}
          </S.SubHeaderText>
          <S.SubHeaderText>{'in a variety of pots and sizes'}</S.SubHeaderText>
        </Grid>
        <Spacer size={8} />
        <S.CollectionsGridContainer>
          <CollectionsGrid lazy={false} />
        </S.CollectionsGridContainer>
        <Spacer size={2} />
      </Grid>
    </S.PlantsForEverySpaceContainer>
  )
}
