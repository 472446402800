import { styled } from '@mui/material'

export const JoinOurCommunityContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream3};
`

export const HeaderText = styled('div')`
  padding: 0 50px;
  font-family: 'Fraunces';
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`
export const SubHeaderText = styled('div')`
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`
