import React from 'react'
import { getFeaturedCollections } from 'consts/collections'
import { getOptimizedPublicURL } from 'services/cloudinaryService'
import CollectionsListMobile from '@components/CollectionsList/CollectionsLIstMobile/CollectionsListMobile'
import StyledLink from '@components/common/StyledLink/StyledLink'
import * as S from '@components/CollectionsList/CollectionListItem2/CollectionListItem2.styles'
import * as D from './CollectionsGrid.style'
import analyticsManager from 'services/analytics/analyticsManager'
import EVENT_NAMES from 'consts/eventNames'
import { Spacer } from '@components/ui'
import { useHomepageEmailCaptureButton } from 'hooks/useHomepageCTAButton'

const featuredCollections = getFeaturedCollections()

const COLLECTION_IMAGE_WIDTH = 284
const COLLECTION_IMAGE_HEIGHT = 331

const defaultMeasurements = {
  width: COLLECTION_IMAGE_WIDTH,
  height: COLLECTION_IMAGE_HEIGHT,
}

const collectionsMeasurements = {
  width: COLLECTION_IMAGE_WIDTH * 3,
  height: Math.floor(COLLECTION_IMAGE_HEIGHT * 1.7),
}

const getOptimizedURL = (url: string, measurements = defaultMeasurements) =>
  getOptimizedPublicURL(url, measurements)

interface CollectionItemData {
  name: string
  text: string
  imgUrl: string
  imgMeasurements: typeof defaultMeasurements | typeof collectionsMeasurements
}

export const COLLECTIONS_LIST_DATA: CollectionItemData[] = [
  {
    name: featuredCollections.SMALL,
    text: 'Small',
    imgUrl: getOptimizedURL(`category-${featuredCollections.SMALL}`),
    imgMeasurements: defaultMeasurements,
  },
  {
    name: featuredCollections.MEDIUM,
    text: 'Medium',
    imgUrl: getOptimizedURL(`category-${featuredCollections.MEDIUM}`),
    imgMeasurements: defaultMeasurements,
  },
  {
    name: featuredCollections.LARGE,
    text: 'Large',
    imgUrl: getOptimizedURL(`category-${featuredCollections.LARGE}`),
    imgMeasurements: defaultMeasurements,
  },
  {
    name: featuredCollections.HUGE,
    text: 'Huge',
    imgUrl: getOptimizedURL(`category-${featuredCollections.HUGE}`),
    imgMeasurements: defaultMeasurements,
  },
  {
    name: featuredCollections.TREE_FORM,
    text: 'Tree Form',
    imgUrl: getOptimizedURL(
      `category-${featuredCollections.TREE_FORM}`,
      collectionsMeasurements
    ),
    imgMeasurements: collectionsMeasurements,
  },
  {
    name: featuredCollections.COLLECTIONS,
    text: 'Collections',
    imgUrl: getOptimizedURL(
      `category-${featuredCollections.COLLECTIONS}`,
      collectionsMeasurements
    ),
    imgMeasurements: collectionsMeasurements,
  },
]

// Define the type for the function's props
interface ICollectionsGridProps {
  lazy?: boolean
}

const CollectionsGrid: React.FC<ICollectionsGridProps> = ({ lazy = true }) => {
  const collectionsList = COLLECTIONS_LIST_DATA
  const { redirectToEmailCapture, emailCaptureLink } =
    useHomepageEmailCaptureButton()

  const onCollectionClicked = (name: string) => {
    analyticsManager.track(EVENT_NAMES.COLLECTIONS_GRID_CLICKED, {
      collection: name,
    })
  }

  const loadingType = lazy ? 'lazy' : 'eager'

  return (
    <CollectionsListMobile>
      {collectionsList.map(
        ({ name, text, imgUrl, imgMeasurements }: CollectionItemData) => {
          return (
            <StyledLink
              key={name}
              onClick={() => onCollectionClicked(name)}
              href={
                redirectToEmailCapture
                  ? emailCaptureLink(`/products/${name}`)
                  : `/products/${name}`
              }
            >
              <S.CollectionItem $alignItems='center'>
                <S.StyledImage
                  src={imgUrl}
                  alt={name}
                  {...imgMeasurements}
                  loading={loadingType}
                />
                <Spacer size={6} />
                <D.CollectionItemText>{text}</D.CollectionItemText>
              </S.CollectionItem>
            </StyledLink>
          )
        }
      )}
    </CollectionsListMobile>
  )
}

export default CollectionsGrid
