import { styled } from '@mui/material'
import {
  LAYOUT_DESKTOP_THRESHOLD,
  LAYOUT_MOBILE_MAX_WIDTH,
} from '@utils/styleUtils'

export const ImageGallaryContainer = styled('div')`
  width: 95vw;
  /* the parent */
  .slick-list {
    margin: 0px -16px;
  }
  @media only screen and (min-width: ${LAYOUT_MOBILE_MAX_WIDTH}px) and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) {
    width: ${LAYOUT_MOBILE_MAX_WIDTH}px;
    .slick-list {
      margin: 0px 0px;
    }
  }
`

// Dots styles
export const DotsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`

export const Dot = styled('div')`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #171615;
  opacity: 0.5;
  margin: 0px 4px;
`

// Single Card styles
export const SingleCardContainer = styled('div')`
  width: 73vw;
  height: calc(73vw * 1.082);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #ededed;
  border-radius: 8px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
  background: #ffffff;
  @media only screen and (min-width: ${LAYOUT_MOBILE_MAX_WIDTH}px) and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) {
    width: calc(0.73 * ${LAYOUT_MOBILE_MAX_WIDTH}px);
    height: calc(0.73 * ${LAYOUT_MOBILE_MAX_WIDTH}px);
  }
`

export const SingleCardTag = styled('div')`
  height: 40px;
  width: 100%;
  padding-left: 20px;
  line-height: 40px;
  text-align: left;
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black3};
`

export const ImageContainer = styled('div')`
  position: relative;
  width: 100%;
  height: calc(73vw * 0.945);
  border-radius: 8px;
`
