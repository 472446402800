import React from 'react'
import NextHead from 'next/head'
import { useTheme } from '@config/Theme/theme'
import { builder } from '@builder.io/react'
import { OrganizationJsonLd } from 'next-seo'
import { DefaultNavbar } from '@components/common/Navbar/DefaultNavbar'
import { BUILDER_MODELS } from 'consts/models'
import { SEOContainer } from '@components/SEO/SEOContainer'
import { NewHomepage } from '@components/Pages/NewHomepage/NewHomepage'
import { getFullURL } from '@utils/urlUtils'
import { isEarlyAccessViewer } from '@lib/utils/early-access/early-access-viewer'
import { REGULAR_ANNOUNCEMENT_BAR } from '@components/Announcement/AnnouncementBar/AnnouncementBarConts'

// Call building in getStaticProps
export async function getStaticProps({ locale }) {
  const [faqResponse] = await Promise.all([
    await builder
      .get(BUILDER_MODELS.HOMEPAGE_FAQ, {
        query: {
          name: 'homepage-faq',
        },
      })
      .toPromise(),
  ])

  return {
    props: {
      faqData: faqResponse?.data?.items || [],
      announcement: REGULAR_ANNOUNCEMENT_BAR,
      locale,
    },
    revalidate: 60,
  }
}

const videoName = `hero-video-mobile`
const poster = `${videoName}-poster.webp`
const preFetchHeroPosterLink = (
  <link
    rel='preload'
    href={poster}
    as='image'
    imageSizes='100vw'
    imageSrcSet={poster}
    fetchPriority='high'
  />
)

const Homepage = ({ faqData = [], locale }) => {
  isEarlyAccessViewer()
  const theme = useTheme()
  const seoData = {
    title: 'easyplant: Easy-Care Self-Watering Plants',
    description:
      'Keep houseplants alive effortlessly with easyplant! Shop beautiful, live, indoor plants, potted in our simple, self-watering pots shipped free right to you.',
    image: null,
    locale,
    canonical: getFullURL('/'),
  }

  return (
    <>
      <NextHead>
        <meta name='theme-color' content={theme.colors.cream3} />
        {preFetchHeroPosterLink}
      </NextHead>
      <SEOContainer seoData={seoData}>
        <OrganizationJsonLd
          name='Easyplant'
          url='https://easyplant.com'
          logo='https://easyplant.com/logo.svg'
          sameAs={[
            'https://www.facebook.com/itseasyplant',
            'https://www.instagram.com/easyplant',
          ]}
        />
        <NewHomepage faqData={faqData} />
      </SEOContainer>
    </>
  )
}

Homepage.DesktopContainer = React.Fragment
Homepage.Navbar = DefaultNavbar

export default Homepage
