import SEO from './SEO'

export function SEOContainer({ seoData, children }) {
  const shouldWriteSEO = seoData?.title || seoData?.canonical
  return (
    <>
      {shouldWriteSEO && <SEO {...seoData} />}
      {children}
    </>
  )
}
