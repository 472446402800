import Helpers from '../helpers'
import Cookies from 'js-cookie'
import { EARLY_ACCESS_COOKIE_KEY, EARLY_ACCESS_KEY } from './consts'

export const isEarlyAccessViewer = () => {
  const earlyAccessParam = Helpers.getURLParam(EARLY_ACCESS_KEY)
  const isEarlyAccess = earlyAccessParam === 'true' || false

  if (!isEarlyAccess) {
    return Cookies.get(EARLY_ACCESS_COOKIE_KEY) === 'true'
  }

  Cookies.set(EARLY_ACCESS_COOKIE_KEY, 'true', { expires: 30 })
  return true
}
