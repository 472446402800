import { styled } from '@mui/material'

export const MainContainer = styled('div')<{ $backgroundColor }>`
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor || theme.colors2.black100};
  padding: 0 calc((100% - 1220px) / 2); // screen width should be 1220px and we add equal padding on the sides
  width: 100%;
`

export const FirstRowContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const FirstRowHeaderText = styled('div')`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  font-family: 'Fraunces';
  font-weight: 400;
  font-size: 40px;
  line-height: 135%;
  color: ${({ theme }) => theme.colors.black3};
`

export const LogoBubbleContainer = styled('div')`
  background-image: url('https://res.cloudinary.com/easyplant/image/upload/v1688468774/homepage2/CarouselBubble.svg');
  background-size: cover;
  background-position: center;
  width: 558px;
  height: 237px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LogoBubbleText = styled('div')`
  padding: 0 20px;
  margin-top: -20px;
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
`

export const LogoBubbleSubText = styled('div')`
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`

export const ArrowsContainer = styled('div')`
  position: absolute;
  bottom: 40px;
  right: 12px;
  width: 96px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const BubbleArrowCursor = styled('div')`
  cursor: pointer;
  height: 40px;
`

export const CarouselContainer = styled('div')`
  position: relative;
  margin-top: 30px;
  width: 100%;
  overflow: hidden;
`

export const SliderGradientLeft = styled('div')<{ $color }>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: -5vw;
  width: 10vw;
  height: 60px;
  background: ${({ $color }) =>
    `linear-gradient(90deg, ${$color} 39.22%, ${$color}00 100%)`};
`

export const SliderGradientRight = styled('div')<{ $color }>`
  position: absolute;
  z-index: 1;
  top: 0;
  right: -4vw;
  width: 10vw;
  height: 60px;
  background: ${({ $color }) =>
    `linear-gradient(270deg, ${$color} 39.22%, ${$color}00 100%)`};
`

export const CarouselItem = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 80px;
`

export const ImageWrapper = styled('div')`
  position: relative;
  width: 124px;
  height: 48px;
  cursor: pointer;
`

export const UnderlineActiveSlide = styled('div')`
  margin-top: 8px;
  width: 124px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black3};
`
