import React from 'react'
import * as S from './FAQ.style'
import Spacer from '@components/ui/Spacer'
import { FAQList } from '@components/FAQList/FAQListV2'

export const FAQ = ({ faqData, origin, showFooterSeperator }) => {
  return (
    <S.MainContainer>
      <Spacer size={40} />
      <S.HorizontalContainer>
        <S.HeaderContainer>
          <S.HeaderText>{'Frequently Asked'}</S.HeaderText>
          <S.HeaderText>{'Questions'}</S.HeaderText>
        </S.HeaderContainer>
        <Spacer size={24} />
        <S.FAQContainer>
          <FAQList faqData={faqData} origin={origin} useInnerHtml={true} />
        </S.FAQContainer>
      </S.HorizontalContainer>
      <Spacer size={40} />
      {showFooterSeperator && <S.FooterSeparator />}
    </S.MainContainer>
  )
}
