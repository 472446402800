import React from 'react'
import { DesktopHeroVideo } from '@components/HeroVideo/HeroVideo'
import { TestimonialsCarouselSection } from './TestimonialsCarousel/TestimonialsCarouselSection'
import { PlantsForEverySpace } from './PlantsForEverySpace/PlantsForEverySpace'
import { HowItWorks } from './HowItWorks/HowItWorks'
import { MarketingSections } from './MarketingSections/MarketingSections'
import { SocialMediaSection } from './SocialMediaSection/SocialMediaSection'
import { PAGES } from '../../../../consts/pages'
import { FAQ } from '@components/FAQ/FAQ'

export const HomepageDesktop = ({ faqData }) => {
  return (
    <div>
      <DesktopHeroVideo />
      <TestimonialsCarouselSection />
      <HowItWorks />
      <PlantsForEverySpace />
      <MarketingSections />
      <SocialMediaSection />
      {faqData && faqData.length > 0 && (
        <FAQ faqData={faqData} origin={PAGES.HOMEPAGE} />
      )}
    </div>
  )
}
