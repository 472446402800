import React from 'react'
import * as S from './SocialMediaSection.style'
import Spacer from '@components/ui/Spacer'
import { CommunityCarousel } from './CommunityCarousel'

export const SocialMediaSection = () => {
  return (
    <S.MainContainer>
      <Spacer size={40} />
      <S.HeaderText>{'Join Our Community'}</S.HeaderText>
      <Spacer size={16} />
      <S.SubHeaderText>
        {'Share your thriving plants using @easyplant to be featured'}
      </S.SubHeaderText>
      <Spacer size={32} />
      <CommunityCarousel />
      <Spacer size={24} />
    </S.MainContainer>
  )
}
