import React, { forwardRef, RefObject, useState } from 'react'
import styled from 'styled-components'
import { Video, VideoComponentProps } from '@components/Video/Video'
import PlayButtonIcon from '@components/Video/play-button.svg'

interface OverlayContainerProps {
  $top?: number
}

interface VideoOverlayProps {
  videoSettings: VideoComponentProps
  containerTop?: number
  onPlayButtonClick?: () => void
  BackgroundOverlayComponent?: any
  children: React.ReactNode
}

const VideoOverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  video {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const PlayButton = styled.button`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`

const InnerOverlayContainer = styled.div<OverlayContainerProps>`
  position: absolute;
  ${({ $top }) => {
    if ($top) {
      return `top: ${$top}px`
    } else {
      return `
      top: 45%;
      transform: translate(0, -50%);
      `
    }
  }}
`

const VideoOverlay = forwardRef<HTMLVideoElement, VideoOverlayProps>(
  (
    {
      videoSettings,
      containerTop = 0,
      children,
      onPlayButtonClick,
      BackgroundOverlayComponent,
      ...videoProps
    },
    videoRef: RefObject<HTMLVideoElement>
  ) => {
    const { src, poster } = videoSettings
    const isAutoPlaying = !onPlayButtonClick
    const [isPlaying, setIsPlaying] = useState(false)

    const handlePlayClick = () => {
      if (videoRef.current) {
        setIsPlaying(true)
        videoRef.current.play()
        onPlayButtonClick?.()
      }
    }

    return (
      <VideoOverlayContainer>
        <Video
          ref={videoRef}
          src={src}
          poster={poster}
          autoPlay={isAutoPlaying}
          {...videoProps}
        />
        <BackgroundOverlayComponent>
          <InnerOverlayContainer $top={containerTop}>
            {children}
          </InnerOverlayContainer>
        </BackgroundOverlayComponent>
        {!isPlaying && !isAutoPlaying && (
          <PlayButton onClick={handlePlayClick}>
            <PlayButtonIcon alt='Play' />
          </PlayButton>
        )}
      </VideoOverlayContainer>
    )
  }
)

VideoOverlay.displayName = 'VideoOverlay'

export default VideoOverlay
