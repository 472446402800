import React from 'react'
import Image from 'next/image'

import Grid from '@mui/material/Grid'
import * as S from './HowItWorks.style'
import {
  HowItWorksStep1,
  HowItWorksStep2,
  HowItWorksStep3,
} from '@components/icons'
import Spacer from '@components/ui/Spacer'

const steps = [
  {
    stepIcon: <HowItWorksStep1 />,
    textLines: ['Fill the water reservoir', 'once a month'],
    altText: 'How it works step 1',
    imageUrl:
      'https://res.cloudinary.com/easyplant/image/upload/f_auto/homepage2/mobile/how-works-step1.png',
  },
  {
    stepIcon: <HowItWorksStep2 />,
    textLines: ['The plant gets the exact', 'amount of water it needs'],
    upperMargin: 44,
    altText: 'How it works step 2',
    imageUrl:
      'https://res.cloudinary.com/easyplant/image/upload/f_auto/homepage2/mobile/how-works-step2-july23.png',
  },
  {
    stepIcon: <HowItWorksStep3 />,
    textLines: ['Enjoy a beautiful, green,', 'plant-filled home'],
    upperMargin: 44,
    altText: 'How it works step 3',
    imageUrl:
      'https://res.cloudinary.com/easyplant/image/upload/f_auto/homepage2/mobile/how-works-step3.png',
  },
]

export const HowItWorks = () => {
  return (
    <S.HowItWorksContainer>
      <Grid container direction={'column'}>
        <Spacer size={32} />
        <Grid item xs={12}>
          <S.HeaderText>{"Here's How It Works"}</S.HeaderText>
        </Grid>
        {steps.map((step, index) => (
          <HowItWorksStep key={step.textLines[0]} {...step} />
        ))}
      </Grid>
    </S.HowItWorksContainer>
  )
}

const HowItWorksStep = ({
  stepIcon,
  textLines,
  altText,
  imageUrl,
  upperMargin = 24,
}) => (
  <Grid container direction={'row'}>
    <Spacer size={upperMargin} />
    <Grid item container xs={12} justifyContent={'center'} alignItems='center'>
      {stepIcon}
    </Grid>
    <Spacer size={16} />
    <Grid item xs={12}>
      {textLines.map((textLine) => (
        <S.HowItWorksText key={textLine}>{textLine}</S.HowItWorksText>
      ))}
    </Grid>
    <Spacer size={24} />
    <Grid item xs={12}>
      <S.HowItWorksImageContainer>
        <Image
          fill
          style={{ objectFit: 'cover' }}
          src={imageUrl}
          alt={altText}
          sizes='100vw'
        />
      </S.HowItWorksImageContainer>
    </Grid>
  </Grid>
)
