import { styled } from '@mui/material'

export const TestimonialsContainer = styled('div')<{ $backgroundColor }>`
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor || theme.colors2.black100};
`

export const HeaderText = styled('div')`
  font-family: 'Fraunces';
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`

export const TestimonialsLogos = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`
