import { useEffect } from 'react'
import { RenderOnMobile, RenderOnDesktop } from '@config/Theme/sizeDisplays'
import analyticsManager from 'services/analytics/analyticsManager'
import { PAGES } from 'consts/pages'
import { HomepageDesktop } from './Desktop/HomepageDesktop'
import { HomepageMobile } from './Mobile/HomepageMobile'

export const NewHomepage = ({ faqData }) => {
  useEffect(() => {
    analyticsManager.trackScreenView(PAGES.HOMEPAGE)
    // setIsMounted(true)
  }, [])

  return (
    <>
      <RenderOnMobile>
        <HomepageMobile faqData={faqData} />
      </RenderOnMobile>
      <RenderOnDesktop>
        <HomepageDesktop faqData={faqData} />
      </RenderOnDesktop>
    </>
  )
}
