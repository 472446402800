import { styled } from '@mui/material'

export const ImageGallaryContainer = styled('div')`
  width: calc(100% - 16px);
  /* the parent */
  .slick-list {
    margin: 0px -16px;
  }
`

// Dots styles
export const DotsContainer = styled('div')`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`

export const Dot = styled('div')`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #171615;
  opacity: 0.5;
  margin: 0px 4px;
`

// Single Card styles
export const SingleCardContainer = styled('div')`
  cursor: pointer;
  width: 292px;
  height: 316px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #ededed;
  border-radius: 8px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
  background: #ffffff;
`

export const SingleCardTag = styled('div')`
  height: 40px;
  width: 100%;
  padding-left: 20px;
  line-height: 40px;
  text-align: left;
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black3};
`

export const ImageContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 276px;
  border-radius: 8px;
`
