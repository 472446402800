import React, { FC } from 'react'
import { CircularProgress } from '@mui/material'
import * as S from './Loader.styles'
import NoSSR from '@components/common/NoSSR'

const LOADER_TYPES = {
  PAGE: 'page',
  CART: 'cart',
  CONTENT: 'content',
}

const LoaderContainers = {
  [LOADER_TYPES.PAGE]: S.PageLoader,
  [LOADER_TYPES.CONTENT]: S.ContentLoader,
  [LOADER_TYPES.CART]: S.CartLoader,
}

type LoaderProps = {
  type: any
  children?: any
}

const Loader: FC<LoaderProps> = ({
  type = LOADER_TYPES.PAGE,
  children,
  ...otherProps
}) => {
  const LoaderContainer = LoaderContainers[type]

  return (
    <NoSSR>
      <LoaderContainer {...otherProps}>
        <CircularProgress />
        {children && (
          <S.PageLoaderTextContainer>{children}</S.PageLoaderTextContainer>
        )}
      </LoaderContainer>
    </NoSSR>
  )
}

export const PageLoader = () => (
  <Loader type={LOADER_TYPES.PAGE}>loading...</Loader>
)

export const HomepageLoader = () => {
  const LoaderContainer = LoaderContainers[LOADER_TYPES.PAGE]

  return (
    <LoaderContainer>
      <CircularProgress />
      <S.PageLoaderTextContainer>loading...</S.PageLoaderTextContainer>
    </LoaderContainer>
  )
}

export const ContentLoader = () => (
  <Loader type={LOADER_TYPES.CONTENT}>loading...</Loader>
)

export const CartLoader = (props) => (
  <Loader type={LOADER_TYPES.CART} {...props} />
)
