import React, { forwardRef } from 'react'
import styled from 'styled-components'

export interface VideoComponentProps {
  src: string
  poster: string
  autoPlay?: boolean
}

const StyledVideo = styled.video`
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

// Video must be located in our public folder and have both an mp4 and a webm version
export const Video = forwardRef<HTMLVideoElement, VideoComponentProps>(
  ({ src, poster, autoPlay }, videoRef) => (
    <StyledVideo
      ref={videoRef}
      preload='auto'
      poster={poster}
      autoPlay={autoPlay}
      loop
      muted
      playsInline
    >
      <source type='video/mp4' src={`${src}.mp4`} />
      <source type='video/webm' src={`${src}.webm`} />
      video not supported
    </StyledVideo>
  )
)

Video.displayName = 'Video'
