import { styled } from '@mui/material'

export const MainContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream5};
  width: 100%;
  padding: 0 calc((100% - 1220px) / 2); // screen width should be 1220px and we add equal padding on the sides
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -16px;
`

export const FooterSeparator = styled('div')`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`

export const HorizontalContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

export const HeaderContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const HeaderText = styled('div')`
  font-family: Fraunces;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  font-size: 40px;
`

export const FAQContainer = styled('div')`
  flex: 1;
`
