import React from 'react'

import Grid from '@mui/material/Grid'
import * as S from './FAQ.style'
import Spacer from '@components/ui/Spacer'
import { useTheme } from '@config/Theme/theme'
import { FAQList } from '@components/FAQList/FAQListV2'

export const FAQ = ({ faqData, origin, showFooterSeperator }) => {
  const theme = useTheme()

  return (
    <S.FAQContainer>
      <Grid container direction={'column'}>
        <Spacer size={32} />
        <Grid item xs={12}>
          <S.HeaderText>{'Frequently Asked Questions'}</S.HeaderText>
        </Grid>
        <Spacer size={16} />
      </Grid>
      <FAQList
        faqData={faqData}
        containerStyle={{
          marginTop: '8px',
        }}
        titleTextColor={theme.colors.black3}
        textTextColor={theme.colors.darkGray}
        origin={origin}
        useInnerHtml={true}
      />
      <Spacer size={40} />
      {showFooterSeperator && <S.FooterSeparator />}
    </S.FAQContainer>
  )
}
