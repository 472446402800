import { styled } from '@mui/material'
import {
  LAYOUT_DESKTOP_THRESHOLD,
  LAYOUT_MOBILE_MAX_WIDTH,
} from '@utils/styleUtils'

export const HowItWorksContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream5};
  padding: 0 calc((100% - 1220px) / 2); // screen width should be 1220px and we add equal padding on the sides
  width: 100%;
  position: relative;

  @media only screen and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) and (min-width: ${LAYOUT_MOBILE_MAX_WIDTH}px) {
    padding: 0 calc((100% - 750px) / 2);
  }
`

export const HeaderText = styled('div')`
  font-family: 'Fraunces';
  font-family: 'Fraunces';
  font-weight: 400;
  font-size: 40px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`

export const HowItWorksText = styled('div')`
  max-width: 300px;
  font-family: Mixtiles Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black3};
`

export const HowItWorksImageContainer = styled('div')`
  position: relative;
  width: 100%;
  aspect-ratio: 558/682;
`

export const DotsBorder = styled('div')`
  position: absolute;
  top: 175px;
  left: 0;
  right: 0;
  margin: auto;
  width: 24vw;
  height: 2px;
  border: 1px dashed var(--shades-400, #a69f97);
`

export const StepsFlexContainer = styled('div')`
  display: flex;
  flex-direction: row;
`
export const StepContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const StepIconWrapper = styled('div')``
