import React from 'react'
import { NextSeo } from 'next-seo'

function SEO({
  title,
  description = 'Stop killing house plants with easyplant! Shop beautiful, live, indoor plants, potted in our simple, self-watering ceramic pots shipped free right to you.',
  image = undefined,
  locale = 'en-US',
  ...seoData
}) {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        type: 'website',
        locale,
        title,
        description,
        ...(image && {
          images: [
            {
              url: image,
              width: 800,
              height: 600,
              alt: title,
            },
          ],
        }),
      }}
      {...seoData}
    />
  )
}

export default SEO
