import { styled } from '@mui/material'
// import styled from 'styled-components'

export const Container = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream5};
  padding-bottom: 20px;
`

export const VideoPreviewContainer = styled('div')`
  position: relative;
  height: 340px;
  width: 100%;
  margin: 0 auto;
`

export const VideoBGAbsoluteContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const VideoBGContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const VideoPlayerContainer = styled('div')`
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const VideoPlayer = styled('video')`
  border-radius: 4px;
`

export const HeaderText = styled('div')`
  padding: 0 35px;
  font-family: 'Fraunces';
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`
export const SubHeaderText = styled('div')`
  padding: 0 5px;
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`
