import React, { ReactNode } from 'react'
import { getRouteURL, PAGES } from 'consts/pages'
import * as S from './PlantsForEverySpace.style'
import Spacer from '@components/ui/Spacer'
import { getFeaturedCollections } from 'consts/collections'
import { LinkProps } from 'next/link'
import { useHomepageEmailCaptureButton } from 'hooks/useHomepageCTAButton'
import analyticsManager from 'services/analytics/analyticsManager'
import EVENT_NAMES from 'consts/eventNames'
import {
  getOptimizedHomePageURL,
  getOptimizedPublicURL,
} from 'services/cloudinaryService'

const featuredCollections = getFeaturedCollections()

const productTypeImages = {
  [featuredCollections.SMALL]: getOptimizedHomePageURL(
    'plant-size-small',
    {},
    'png'
  ),
  [featuredCollections.MEDIUM]: getOptimizedHomePageURL(
    'plant-size-medium',
    {},
    'png'
  ),
  [featuredCollections.LARGE]: getOptimizedHomePageURL(
    'plant-size-large',
    {},
    'png'
  ),
  [featuredCollections.HUGE]: getOptimizedHomePageURL(
    'plant-size-huge',
    {},
    'png'
  ),
  [featuredCollections.TREE_FORM]: getOptimizedPublicURL(
    `category-${featuredCollections.TREE_FORM}`
  ),
  [featuredCollections.COLLECTIONS]: getOptimizedPublicURL(
    `category-${featuredCollections.COLLECTIONS}`
  ),
}

enum IMAGE_TYPE {
  PRODUCT = 'product',
  COLLECTION = 'collection',
}

interface ImageContainerProps {
  href: LinkProps['href']
  onClick: React.MouseEventHandler<HTMLAnchorElement>
  children?: ReactNode
}

interface IMAGE_CONTAINER {
  [key: string]: React.FC<ImageContainerProps>
}

const IMAGE_CONTAINER: IMAGE_CONTAINER = {
  [IMAGE_TYPE.PRODUCT]: S.ProductTypeImageContainer,
  [IMAGE_TYPE.COLLECTION]: S.CollectionImageContainer,
}
const GRID_CONTAINER = {
  [IMAGE_TYPE.PRODUCT]: S.SingleProductTypeContainer,
  [IMAGE_TYPE.COLLECTION]: S.WideProductTypeContainer,
}

interface GalleryItemContainerProps {
  text: string
  imageUrl: string
  productLink: string
  productType: IMAGE_TYPE
  onClick: () => void
}

export const PlantsForEverySpace = () => {
  const { redirectToEmailCapture, emailCaptureLink } =
    useHomepageEmailCaptureButton()

  const onCollectionClicked = (name: string) => {
    analyticsManager.track(EVENT_NAMES.COLLECTIONS_GRID_CLICKED, {
      collection: name,
    })
  }

  return (
    <S.PlantsForEverySpaceContainer>
      <Spacer size={40} />
      <S.HeaderText>{'Plants For Every Space'}</S.HeaderText>
      <Spacer size={16} />
      <S.SubHeaderText>
        {'Discover our wide selection of plants in a variety of pots and sizes'}
      </S.SubHeaderText>
      <Spacer size={32} />
      <S.ImageGalleryContainer>
        <GalleryItemContainer
          text={featuredCollections.SMALL}
          imageUrl={productTypeImages.small}
          productLink={
            redirectToEmailCapture
              ? emailCaptureLink(getRouteURL(PAGES.SMALL))
              : getRouteURL(PAGES.SMALL)
          }
          productType={IMAGE_TYPE.PRODUCT}
          onClick={() => onCollectionClicked(PAGES.SMALL)}
        />
        <GalleryItemContainer
          text={featuredCollections.MEDIUM}
          imageUrl={productTypeImages.medium}
          productLink={
            redirectToEmailCapture
              ? emailCaptureLink(getRouteURL(PAGES.MEDIUM))
              : getRouteURL(PAGES.MEDIUM)
          }
          productType={IMAGE_TYPE.PRODUCT}
          onClick={() => onCollectionClicked(PAGES.MEDIUM)}
        />
        <GalleryItemContainer
          text={featuredCollections.LARGE}
          imageUrl={productTypeImages.large}
          productLink={
            redirectToEmailCapture
              ? emailCaptureLink(getRouteURL(PAGES.LARGE))
              : getRouteURL(PAGES.LARGE)
          }
          productType={IMAGE_TYPE.PRODUCT}
          onClick={() => onCollectionClicked(PAGES.LARGE)}
        />
        <GalleryItemContainer
          text={featuredCollections.HUGE}
          imageUrl={productTypeImages.huge}
          productLink={
            redirectToEmailCapture
              ? emailCaptureLink(getRouteURL(PAGES.HUGE))
              : getRouteURL(PAGES.HUGE)
          }
          productType={IMAGE_TYPE.PRODUCT}
          onClick={() => onCollectionClicked(PAGES.HUGE)}
        />

        <GalleryItemContainer
          text={'Tree Form'}
          imageUrl={productTypeImages[featuredCollections.TREE_FORM]}
          productLink={
            redirectToEmailCapture
              ? emailCaptureLink(getRouteURL(PAGES.TREE_FORM))
              : getRouteURL(PAGES.TREE_FORM)
          }
          productType={IMAGE_TYPE.COLLECTION}
          onClick={() => onCollectionClicked(PAGES.TREE_FORM)}
        />

        <GalleryItemContainer
          text={featuredCollections.COLLECTIONS}
          imageUrl={productTypeImages.collections}
          productLink={
            redirectToEmailCapture
              ? emailCaptureLink(getRouteURL(PAGES.COLLECTIONS))
              : getRouteURL(PAGES.COLLECTIONS)
          }
          productType={IMAGE_TYPE.COLLECTION}
          onClick={() => onCollectionClicked(PAGES.COLLECTIONS)}
        />
      </S.ImageGalleryContainer>
      <Spacer size={40} />
    </S.PlantsForEverySpaceContainer>
  )
}

const GalleryItemContainer: React.FC<GalleryItemContainerProps> = ({
  text,
  imageUrl,
  productLink,
  productType,
  onClick,
}) => {
  const ImageContainer = IMAGE_CONTAINER[productType]
  const GridContainer = GRID_CONTAINER[productType]

  return (
    <GridContainer>
      <ImageContainer href={productLink} onClick={onClick}>
        <S.RoundImage fill src={imageUrl} alt={text} sizes='100vw' />
      </ImageContainer>
      <S.PlantSizeTypeText>{text}</S.PlantSizeTypeText>
    </GridContainer>
  )
}
