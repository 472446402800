import Link from 'next/link'
import styled from 'styled-components'
import Image from 'next/image'

export const PlantsForEverySpaceContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream3};
  padding: 0 calc((100% - 1220px) / 2); // screen width should be 1220px and we add equal padding on the sides
`

export const HeaderText = styled('div')`
  font-family: 'Fraunces';
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  letter-spacing: -0.8px;
  color: ${({ theme }) => theme.colors.black3};
`
export const SubHeaderText = styled('div')`
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.black3};
`

export const PlantSizeTypeText = styled('div')`
  font-family: 'Fraunces', sans-serif;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`

export const RoundImage = styled(Image)`
  border-radius: 8px;
`

const BaseImageContainer = styled(Link)`
  border-radius: 4px;
  position: relative;
`

export const ProductTypeImageContainer = styled(BaseImageContainer)`
  aspect-ratio: 267 / 264;
`

export const CollectionImageContainer = styled(BaseImageContainer)`
  aspect-ratio: 290 / 180;
`

export const ImageGalleryContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
`

export const ImageGalleryLeftGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px 24px;
`

export const SingleProductTypeContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const WideProductTypeContainer = styled(SingleProductTypeContainer)`
  grid-column: span 2;
`
