import React from 'react'
import Image from 'next/image'
import * as S from './HowItWorks.style'
import { HowItWorksStep1, HowItWorksStep2 } from '@components/icons'
import Spacer from '@components/ui/Spacer'

const steps = [
  {
    stepIcon: <HowItWorksStep1 />,
    line1: 'Fill the water reservoir',
    line2: 'once a month',
    altText: 'How it works step 1',
    imageUrl:
      'https://res.cloudinary.com/easyplant/image/upload/f_auto/v1688646779/homepage2/desktop-howto-step1.png',
  },
  {
    stepIcon: <HowItWorksStep2 />,
    line1: 'The plant gets the exact',
    line2: 'amount of water it needs',
    altText: 'How it works step 2',
    imageUrl:
      'https://res.cloudinary.com/easyplant/image/upload/f_auto/v1688646779/homepage2/desktop-howto-step2-v2.png',
  },
]

export const HowItWorks = ({ isRound }: { isRound?: boolean }) => {
  return (
    <S.HowItWorksContainer>
      <Spacer size={72} />
      <S.HeaderText>{"Here's How It Works"}</S.HeaderText>
      <Spacer size={32} />
      <S.DotsBorder />
      <S.StepsFlexContainer>
        <HowItWorksStep
          key={111}
          stepIcon={steps[0].stepIcon}
          line1={steps[0].line1}
          line2={steps[0].line2}
          altText={steps[0].altText}
          imageUrl={steps[0].imageUrl}
          isRound={isRound}
        />
        <Spacer size={24} />
        <HowItWorksStep
          key={222}
          stepIcon={steps[1].stepIcon}
          line1={steps[1].line1}
          line2={steps[1].line2}
          altText={steps[1].altText}
          imageUrl={steps[1].imageUrl}
          isRound={isRound}
        />
      </S.StepsFlexContainer>
      <Spacer size={80} />
    </S.HowItWorksContainer>
  )
}

const HowItWorksStep = ({
  stepIcon,
  line1,
  line2,
  altText,
  imageUrl,
  isRound,
}) => (
  <S.StepContainer>
    <Spacer size={32} />
    <S.StepIconWrapper>{stepIcon}</S.StepIconWrapper>
    <Spacer size={24} />

    <S.HowItWorksText>{line1}</S.HowItWorksText>
    <S.HowItWorksText>{line2}</S.HowItWorksText>

    <Spacer size={32} />

    <S.HowItWorksImageContainer>
      <Image
        width={0}
        height={0}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          borderRadius: isRound ? '16px' : 0,
        }}
        src={imageUrl}
        alt={altText}
        sizes='100vw'
      />
    </S.HowItWorksImageContainer>
  </S.StepContainer>
)
