import { styled } from '@mui/material'

export const MainContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream3};
`

export const HeaderText = styled('div')`
  font-family: 'Fraunces';
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  letter-spacing: -0.8px;
  color: ${({ theme }) => theme.colors.black3};
`
export const SubHeaderText = styled('div')`
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.black3};
`
