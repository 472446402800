import { styled } from '@mui/material'
import {
  LAYOUT_DESKTOP_THRESHOLD,
  LAYOUT_MOBILE_MAX_WIDTH,
} from '@utils/styleUtils'

export const ChatWithUsContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream3};
`

export const HeaderText = styled('div')`
  padding: 0 35px;
  font-family: 'Fraunces';
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`
export const SubHeaderText = styled('div')`
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`

export const ChatWithUsButtonContainer = styled('div')`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(hsla(0, 0%, 100%, 0.1)),
      to(hsla(0, 0%, 100%, 0.1))
    );
    background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.1),
      hsla(0, 0%, 100%, 0.1)
    );
    box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.09);
  }
`

export const ChatWithUsButton = styled('button')`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.cream4};
  border-radius: 6px;
`

export const ChatWithUsButtonText = styled('span')`
  font-family: 'Fraunces';
  font-weight: 400;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black3};
`
export const ChatWithUsImageContainer = styled('div')`
  position: relative;
  width: vw;
  height: calc(100vw * 1.06);
  @media only screen and (min-width: ${LAYOUT_MOBILE_MAX_WIDTH}px) and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) {
    width: ${LAYOUT_MOBILE_MAX_WIDTH}px;
    height: calc(${LAYOUT_MOBILE_MAX_WIDTH}px * 1.06);
  }
`
