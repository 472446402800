import React from 'react'

import Grid from '@mui/material/Grid'
import * as S from './Testimonials.style'
import { TestimonialsLogos } from '@components/icons'
import Spacer from '@components/ui/Spacer'
import theme from '@config/Theme/theme'

export const Testimonials = () => {
  return (
    <S.TestimonialsContainer $backgroundColor={theme.colors.cream3}>
      <Grid container direction={'column'}>
        <Spacer size={33} />
        <Grid item xs={12}>
          <S.HeaderText>{'Over 1,000,000'}</S.HeaderText>
          <S.HeaderText>{'Plants Shipped'}</S.HeaderText>
        </Grid>
        <S.TestimonialsLogos>
          <TestimonialsLogos />
        </S.TestimonialsLogos>
        <Spacer size={33} />
      </Grid>
    </S.TestimonialsContainer>
  )
}
