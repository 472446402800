import { styled } from '@mui/material'

export const PlantsForEverySpaceContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.cream3};
  padding-bottom: 16px;
`

export const HeaderText = styled('div')`
  padding: 0 35px;
  font-family: 'Fraunces';
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`
export const SubHeaderText = styled('div')`
  font-family: 'Mixtiles Sans';
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black3};
`

export const CollectionsGridContainer = styled('div')`
  padding: 0 8px;
`
