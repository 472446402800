import React, { useState, useRef, useCallback } from 'react'
import useResizeObserver from 'lib/utils/resizeObserver'
import * as S from './CommunityCarousel.style'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  COMMUNITY_IMAGES,
  imageTags,
} from '@components/Pages/NewHomepage/Mobile/JoinOurCommunity/CommunityCarousel'
import Slider from 'react-slick'
import Image from 'next/image'

const imagesInfinite =
  COMMUNITY_IMAGES.concat(COMMUNITY_IMAGES).concat(COMMUNITY_IMAGES)
const imageTagsInfinite = imageTags.concat(imageTags).concat(imageTags)

export const CommunityCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [settings, setSettings] = useState({
    dots: false,
    arrows: false,
    swipeToSlide: true,
    infinite: true,
    centerMode: true,
    speed: 500,
    initialSlide: COMMUNITY_IMAGES.length,
    slidesToShow: 1, // will be updated on resize on first mount
    slidesToScroll: 1,
    beforeChange: (_current, next) => setCurrentSlide(Math.floor(next)),
  })

  const sliderRef = useRef(null)

  const onResize = useCallback(
    (target: HTMLDivElement) => {
      let newSlidesToShow = window.innerWidth / 326
      if (newSlidesToShow != settings.slidesToShow) {
        setSettings({ ...settings, slidesToShow: newSlidesToShow })
      }
    },
    [settings]
  )

  const containerRef = useResizeObserver(onResize)

  const onCardClick = (idx) => {
    setCurrentSlide(idx)
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(idx)
    }
  }

  return (
    <S.ImageGallaryContainer ref={containerRef}>
      <Slider {...settings} ref={sliderRef}>
        {imagesInfinite.map((image, index) => (
          <SingleCard
            key={image}
            image={image}
            imageTag={imageTagsInfinite[index]}
            onCardClick={() => onCardClick(index)}
          />
        ))}
      </Slider>
      <S.DotsContainer>
        {COMMUNITY_IMAGES.map((image, index) => (
          <S.Dot
            onClick={() => onCardClick(index + COMMUNITY_IMAGES.length)}
            key={image}
            style={
              index == currentSlide % COMMUNITY_IMAGES.length
                ? { opacity: 1 }
                : {}
            }
          />
        ))}
      </S.DotsContainer>
    </S.ImageGallaryContainer>
  )
}

const SingleCard = ({ image, imageTag, onCardClick }) => (
  <S.SingleCardContainer onClick={onCardClick}>
    <S.ImageContainer>
      <Image
        fill
        style={{ objectFit: 'cover' }}
        src={image}
        alt={imageTag}
        sizes='100vw'
      />
    </S.ImageContainer>
    <S.SingleCardTag>{imageTag}</S.SingleCardTag>
  </S.SingleCardContainer>
)
