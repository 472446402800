import React from 'react'

import Grid from '@mui/material/Grid'
import * as S from './JoinOurCommunity.style'
import Spacer from '@components/ui/Spacer'
import { CommunityCarousel } from './CommunityCarousel'

export const JoinOurCommunity = () => {
  return (
    <S.JoinOurCommunityContainer>
      <Grid container direction={'column'}>
        <Spacer size={32} />
        <Grid item xs={12}>
          <S.HeaderText>{'Join Our Community'}</S.HeaderText>
        </Grid>
        <Spacer size={16} />
        <Grid item xs={12}>
          <S.SubHeaderText>
            {'Share your thriving plants using'}
          </S.SubHeaderText>
          <S.SubHeaderText>{'@easyplant to be featured'}</S.SubHeaderText>
        </Grid>
        <Spacer size={32} />
        <Grid item xs={12}>
          <CommunityCarousel />
        </Grid>
      </Grid>
      <Spacer size={32} />
    </S.JoinOurCommunityContainer>
  )
}
