import React from 'react'
import Image from 'next/image'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import * as S from './TestimonialsCarouselSection.style'
import Spacer from '@components/ui/Spacer'
import {
  BubbleArrowLeft,
  BubbleArrowRight,
  GoodHousekeepingLogo,
  DailyLogo,
  PopsugarLogo,
  InsiderLogo,
  NbcLogo,
  RealLogo,
  SpruceLogo,
  GlamourMagazineLogo,
} from '@components/icons'
import theme from '@config/Theme/theme'

const CAROUSEL_ACTIVE_SLIDE_OFFSET = 2

const companies = [
  {
    logo: RealLogo,
    name: 'Real Simple',
    text: 'The indoor plants at Easyplant include self-watering pots to make things easy for novice plant owners, so you can give your plants a better chance of survival.',
  },
  {
    logo: DailyLogo,
    name: 'The Daily Beast',
    text: 'easyplant is a self-watering plant system that requires watering just once a month, offering an almost fail-proof solution for busy individuals or frequent travelers and transforming plant care from a chore to a breeze.',
  },
  {
    logo: NbcLogo,
    name: 'NBC News',
    text: 'The pot slowly carries water to the plant’s roots as needed, making it easy to take care of.',
  },
  {
    logo: SpruceLogo,
    name: 'The Spruce',
    text: 'easyplant makes it (you guessed it) easy to keep your plants watered and look beautiful with minimal effort... Fuss free plants for busy Moms. All you need to do is fill it once a month and it waters itself.',
  },
  {
    logo: InsiderLogo,
    name: 'Insider',
    text: 'Just fill up the reservoir once a month and let it water itself which works out great when I’m traveling, too.',
  },
  {
    logo: GoodHousekeepingLogo,
    name: 'Good Housekeeping',
    text: 'If you have a history of overwatering or under-watering your houseplants, easyplant provides a solution.',
  },
  {
    logo: GlamourMagazineLogo,
    name: 'Glamour Magazine',
    text: "easyplant's signature vase, which only needs to be filled with water once - there's no green thumb necessary to keep this thing thriving.",
  },
  {
    logo: PopsugarLogo,
    name: 'Popsugar',
    text: "easyplant's self-watering planters, requiring only monthly watering, transform plant care from a challenging task into an effortless joy, making it possible for even the busiest individuals or those lacking a green thumb to enjoy lush, healthy indoor greenery.",
  },
]

export const TestimonialsCarouselSection = () => {
  const [activeSlide, setActiveSlide] = React.useState(companies.length * 3)
  const companiesConcat = companies
    .concat(companies)
    .concat(companies)
    .concat(companies)
    .concat(companies)
    .concat(companies)
    .concat(companies)
    .concat(companies)

  return (
    <S.MainContainer $backgroundColor={theme.colors2.black20}>
      <Spacer size={38} />
      <S.FirstRowContainer>
        <S.FirstRowHeaderText>
          <span>{'Over 1,000,000'}</span>
          <span>{'Plants Shipped'}</span>
        </S.FirstRowHeaderText>
        <S.LogoBubbleContainer>
          <S.LogoBubbleText>
            {`"${
              companiesConcat[activeSlide + CAROUSEL_ACTIVE_SLIDE_OFFSET].text
            }"`}
          </S.LogoBubbleText>
          <Spacer size={24} />
          <S.LogoBubbleSubText>
            {companiesConcat[activeSlide + CAROUSEL_ACTIVE_SLIDE_OFFSET].name}
          </S.LogoBubbleSubText>
        </S.LogoBubbleContainer>
        <S.ArrowsContainer>
          <S.BubbleArrowCursor>
            <BubbleArrowLeft
              onClick={() => {
                if (activeSlide + CAROUSEL_ACTIVE_SLIDE_OFFSET == 4) {
                  return
                }

                setActiveSlide(activeSlide - 1)
              }}
            />
          </S.BubbleArrowCursor>
          <S.BubbleArrowCursor>
            <BubbleArrowRight
              onClick={() => {
                if (
                  activeSlide + CAROUSEL_ACTIVE_SLIDE_OFFSET ==
                  companiesConcat.length - 1
                ) {
                  return
                }
                setActiveSlide(activeSlide + 1)
              }}
            />
          </S.BubbleArrowCursor>
        </S.ArrowsContainer>
      </S.FirstRowContainer>
      {/* <VariableWidth /> */}
      <TestimonialsCarousel
        companies={companiesConcat}
        activeSlide={activeSlide}
        setActiveSlide={setActiveSlide}
      />
      <Spacer size={49} />
    </S.MainContainer>
  )
}

const TestimonialsCarousel = ({ companies, activeSlide, setActiveSlide }) => {
  const settings = {
    infiniteLoop: false,
    showArrows: false,
    showStatus: false,
    showIndicators: false,
    showThumbs: false,
    centerMode: true,
    emulateTouch: false,
    centerSlidePercentage: 15,
  }

  return (
    <S.CarouselContainer>
      <S.SliderGradientLeft $color={theme.colors2.black20} />
      <S.SliderGradientRight $color={theme.colors2.black20} />
      <Carousel {...settings} selectedItem={activeSlide}>
        {companies.map((company, index) => (
          <S.CarouselItem key={company.name}>
            <S.ImageWrapper
              onClick={() => {
                setActiveSlide(index - CAROUSEL_ACTIVE_SLIDE_OFFSET)
              }}
            >
              <Image
                src={company.logo}
                alt={company.name}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                sizes='100vw'
              />
            </S.ImageWrapper>
            {index == activeSlide + CAROUSEL_ACTIVE_SLIDE_OFFSET && (
              <S.UnderlineActiveSlide />
            )}
          </S.CarouselItem>
        ))}
      </Carousel>
    </S.CarouselContainer>
  )
}
