import React from 'react'
import NoSSR from '@components/common/NoSSR'
import analyticsManager from 'services/analytics/analyticsManager'
import EVENT_NAMES from 'consts/eventNames'
import { MobileHeroVideo } from '@components/HeroVideo/HeroVideo'
import { Testimonials } from './Testimonials/Testimonials'
import { HowItWorks } from './HowItWorks/HowItWorks'
import { PlantsForEverySpace } from './PlantsForEverySpace/PlantsForEverySpace'
import { VideoPreview } from './VideoPreview/VideoPreview'
import { ChatWithUs } from './ChatWithUs/ChatWithUs'
import { GiftThatLast } from './GiftThatLast/GiftThatLast'
import { JoinOurCommunity } from './JoinOurCommunity/JoinOurCommunity'
import { FloatingButton } from '@components/ui/Button/FloatingButton'
import { ANALYTICS_SERVICES_NAMES } from 'services/analytics/analyticsConfig'
import { TIKTOK_EVENT_NAMES } from 'services/analytics/TiktokService'
import { useHomepageCTAButton } from 'hooks/useHomepageCTAButton'
import { PAGES } from '../../../../consts/pages'
import { FAQ } from '@components/FAQ/FAQ'
import useSafeRouterPush from 'hooks/useSafeRouterPush'

export const HomepageMobile = ({ faqData }) => {
  const safeRouterPush = useSafeRouterPush()
  const { ctaText, ctaLink } = useHomepageCTAButton()
  const tiktokService = analyticsManager.getService(
    ANALYTICS_SERVICES_NAMES.TIKTOK
  )

  return (
    <div>
      <MobileHeroVideo />
      <Testimonials />
      <HowItWorks />
      <PlantsForEverySpace />
      <VideoPreview />
      <ChatWithUs />
      <GiftThatLast />
      <JoinOurCommunity />
      {faqData && faqData.length > 0 && (
        <FAQ faqData={faqData} origin={PAGES.HOMEPAGE} />
      )}
      <NoSSR>
        <FloatingButton
          animate
          onClick={() => {
            tiktokService?.trackTiktokEvent(TIKTOK_EVENT_NAMES.CLICK_BUTTON)
            analyticsManager.track(EVENT_NAMES.HOMEPAGE_SHOP_PLANTS_CLICKED, {
              url: ctaLink,
              device: 'mobile',
            })
            safeRouterPush(ctaLink)
          }}
          height={53}
          data-testid='cta-mobile'
        >
          {ctaText}
        </FloatingButton>
      </NoSSR>
    </div>
  )
}
