import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import VideoOverlay from '@components/Video/VideoOverlay'
import analyticsManager from '../../services/analytics/analyticsManager'
import EVENT_NAMES from '../../consts/eventNames'
import { ANALYTICS_SERVICES_NAMES } from '../../services/analytics/analyticsConfig'
import TransparentButton from '@components/ui/Button/TransparentButton'
import { useHomepageCTAButton } from '../../hooks/useHomepageCTAButton'
import { LAYOUT_DESKTOP_THRESHOLD } from '@utils/styleUtils'
import { TIKTOK_EVENT_NAMES } from '../../services/analytics/TiktokService'

type Variant = 'mobile' | 'desktop'

interface HeroVideoProps {
  variant: Variant
  usePlayButton?: boolean
  darkOverlay?: boolean
}

const OverlayActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const OverLayText = styled.div<{ $variant: Variant }>`
  color: white;
  font-size: ${({ $variant }) => ($variant === 'mobile' ? 40 : 64)}px;
  line-height: ${({ $variant }) => ($variant === 'mobile' ? 54 : 86)}px;
  font-weight: 500;
  letter-spacing: -0.02em;
  font-family: 'Fraunces', sans-serif;
  text-align: center;
`

const TransparentButtonContainer = styled.div`
  margin-top: 24px;
  width: 192px;
  height: 60px;
`

const BackgroundOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: inherit;

  // In mobile, we need this so that the play button would work when iPhones are in "low-battery" mode
  @media only screen and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) {
    pointer-events: none;
  }
`

const DarkBackgroundOverlayContainer = styled(BackgroundOverlayContainer)`
  background: rgba(0, 0, 0, 0.2);
`

let watchedThreeSeconds = false
let watchedFiveSeconds = false
let watchedTenSeconds = false
let watchedFifteenSeconds = false

const updateVideoTimeWatched = (seconds) => {
  analyticsManager.track(
    EVENT_NAMES.HERO_VIDEO_SECONDS_WATCHED,
    {
      seconds,
    },
    [ANALYTICS_SERVICES_NAMES.META]
  )
}
function HeroVideo({
  variant,
  usePlayButton,
  darkOverlay,
}: HeroVideoProps): JSX.Element {
  const { ctaText, ctaLink } = useHomepageCTAButton()
  const isMobile = variant === 'mobile'
  const videoName = `hero-video-${variant}`
  const poster = `${videoName}-poster.webp`
  const heroVideoRef = useRef<HTMLVideoElement>(null)
  const BackgroundOverlayComponent = darkOverlay
    ? DarkBackgroundOverlayContainer
    : BackgroundOverlayContainer

  useEffect(() => {
    const heroVideo = heroVideoRef.current

    const playHandler = () => {
      analyticsManager.track(EVENT_NAMES.HERO_VIDEO_PLAYED, {}, [
        ANALYTICS_SERVICES_NAMES.META,
      ])
    }

    const timeUpdater = () => {
      const currentTime = Math.floor(heroVideo.currentTime)

      if (currentTime >= 3 && !watchedThreeSeconds) {
        watchedThreeSeconds = true
        updateVideoTimeWatched(3)
      }

      if (currentTime >= 5 && !watchedFiveSeconds) {
        watchedFiveSeconds = true
        updateVideoTimeWatched(5)
      }

      if (currentTime >= 10 && !watchedTenSeconds) {
        watchedTenSeconds = true
        updateVideoTimeWatched(10)
      }

      if (currentTime >= 15 && !watchedFifteenSeconds) {
        watchedFifteenSeconds = true
        updateVideoTimeWatched(15)
      }
    }

    if (heroVideo) {
      heroVideo.addEventListener('play', playHandler)
      heroVideo.addEventListener('timeupdate', timeUpdater)
    }

    return () => {
      if (heroVideo) {
        heroVideo.removeEventListener('play', playHandler)
        heroVideo.removeEventListener('timeupdate', timeUpdater)
      }
    }
  }, [])

  const handlePlayButtonClick = () => {
    analyticsManager.track(EVENT_NAMES.HERO_PLAY_BUTTON_CLICKED, {}, [
      ANALYTICS_SERVICES_NAMES.META,
    ])
  }

  const onPlayButtonClick = usePlayButton ? handlePlayButtonClick : null

  const onShopPlantsClicked = () => {
    analyticsManager
      .getService(ANALYTICS_SERVICES_NAMES.TIKTOK)
      ?.trackTiktokEvent(TIKTOK_EVENT_NAMES.CLICK_BUTTON)
    analyticsManager.track(EVENT_NAMES.HOMEPAGE_SHOP_PLANTS_CLICKED, {
      url: ctaLink,
    })
  }
  const textTopOverlay = isMobile ? 63 : null

  return (
    <VideoOverlay
      ref={heroVideoRef}
      containerTop={textTopOverlay}
      BackgroundOverlayComponent={BackgroundOverlayComponent}
      onPlayButtonClick={onPlayButtonClick}
      videoSettings={{
        src: videoName,
        poster: poster,
      }}
    >
      <OverlayActionsContainer>
        <OverLayText $variant={variant}>
          <div>Our Plants</div>
          <div>Water Themselves</div>
        </OverLayText>
        {!isMobile && (
          <TransparentButtonContainer>
            <TransparentButton
              handleOnClick={onShopPlantsClicked}
              text={ctaText}
              link={ctaLink}
            />
          </TransparentButtonContainer>
        )}
      </OverlayActionsContainer>
    </VideoOverlay>
  )
}

const MobileVideoContainer = styled.div`
  height: calc(100vh - 200px);
`

const DesktopVideoContainer = styled.div`
  height: 75vh;
`

export const MobileHeroVideo = () => (
  <MobileVideoContainer>
    <HeroVideo variant='mobile' />
  </MobileVideoContainer>
)

export const DesktopHeroVideo = () => (
  <DesktopVideoContainer>
    <HeroVideo variant='desktop' darkOverlay />
  </DesktopVideoContainer>
)

export default HeroVideo
