import React from 'react'
import Grid from '@mui/material/Grid'
import Image from 'next/image'
import * as S from './ChatWithUs.style'
import Helpers from 'lib/utils/helpers'
import Spacer from '@components/ui/Spacer'
import { ChatWithUsIcon } from '@components/icons'

export const ChatWithUs = () => {
  return (
    <S.ChatWithUsContainer>
      <Grid container direction={'column'}>
        <Spacer size={44} />
        <Grid item xs={12}>
          <S.HeaderText>{'We’re Here'}</S.HeaderText>
          <S.HeaderText>{'To Make It Easy'}</S.HeaderText>
        </Grid>
        <Spacer size={16} />
        <Grid item xs={12}>
          <S.SubHeaderText>
            {'Get in touch with our plant experts for'}
          </S.SubHeaderText>
          <S.SubHeaderText>{'personal plant care guidance'}</S.SubHeaderText>
        </Grid>
        <Spacer size={16} />
        <ChatWithUsButton
          onClick={() => {
            try {
              Helpers.openIntercomChat(`Homepage Mobile`)
            } catch (e) {
              console.error(e)
            }
          }}
        />
        <Spacer size={24} />
        <S.ChatWithUsImageContainer>
          <Image
            fill
            style={{ objectFit: 'cover' }}
            src={
              'https://res.cloudinary.com/easyplant/image/upload/f_auto/homepage2/mobile/chat-with-us-v2.png'
            }
            alt={'Chat with us'}
            sizes='100vw'
          />
        </S.ChatWithUsImageContainer>
      </Grid>
    </S.ChatWithUsContainer>
  )
}

export const ChatWithUsButton = ({ onClick }) => (
  <S.ChatWithUsButtonContainer onClick={onClick}>
    <S.ChatWithUsButton>
      <ChatWithUsIcon />
      <Spacer size={8} />
      <S.ChatWithUsButtonText>{'Chat With Us'}</S.ChatWithUsButtonText>
    </S.ChatWithUsButton>
  </S.ChatWithUsButtonContainer>
)
